.admin {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
}

header {
	background: #222;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 15px;	
}
header .logo {
	color: #888;	
}
header .logo span {
	color: #0b82d3;
}
header nav {
	display: flex;
}
header nav a {
	color: #999;
	padding: 0 20px;
	text-transform: uppercase;
	font-size: 12px;
	display: flex;
	align-items: center;
}
header nav a svg {
	margin-right: 5px;
	font-size: 14px;
}
header nav a:hover {
	color: #FF0;
}


main {
	display: flex;
	flex: 1;
}

main nav {
	width: 200px;
	height: 100%;
	background: #333;
}
main nav .logo {
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #777;
	margin: 15px auto;
}
main nav .logo img {
	max-width: 130px;
}
main nav a {
	color: #fff;
	display: flex;
	background: #444;
	height: 40px;
	align-items: center;
	padding: 0 15px;
	margin-bottom: 1px;
}
main nav a:hover {
	color: #FF0;
	background: #393939;
}

main nav a svg {
	margin-right: 7px;
	font-size: 18px;
}


main section {	
	overflow-y: auto;	
	height: calc(100vh - 50px - 30px);	
	padding: 15px 15px 150px !important;
}
main section nav {
	background: none;
}

main section .submenu {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
}




footer {
	background: #222;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 11px;
}



main section nav {
	background: none;
}



main section h1 {		
	font-size: 22px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #333;
	padding: 0 15px;
	font-weight: 400;
}
main section h1 svg {
	margin-right: 7px;
}


.ant-modal-body h2, 
main section h2 {		
	font-size: 20px;
	display: flex;
	align-items: center;			
	border-bottom: 1px solid #333;
	margin-bottom: 15px;
	font-weight: 700;
}
.ant-modal-body h2 svg,
main section h2 svg {
	margin-right: 5px;
}





.widget {
	box-shadow: 0 0 3px #999;
	padding: 15px;
	background: #fff;
}

.widget h2 {
	background: #555;
	color: #FFF;
	font-weight: 300;
	padding: 0 15px;
	font-size: 18px;
	display: flex;
	height: 35px;
	align-items: center;
	border: none;	
}
.widget h2 svg {
	margin-right: 7px;
	font-size: 20px;
}



.infoItem {
	margin-bottom: 15px;
}
.infoItem p {
	margin: 0;
}




.box {
	background: #fff;
	padding: 15px;
	box-shadow: 0 0 3px #999;	
	height: 100%;
	margin-bottom: 25px;
}
.box h2 {
	background: #555;
	color: #FFF;
	font-weight: 300;
	padding: 0 15px;
	font-size: 18px;
	display: flex;
	height: 35px;
	align-items: center;
	border: none;
}
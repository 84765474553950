.admin {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;	
}

header {
	background: #222;		
}
header .container {
	width: 100%;
	padding: 0 15px;	
	max-width: 1440px;
	margin: 0 auto;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
header .logo {
	color: #888;	
}
header .logo span {
	color: #0b82d3;
}
header nav {
	display: flex;
}
header nav a {
	color: #999;
	padding: 0 20px;
	text-transform: uppercase;
	font-size: 12px;
	display: flex;
	align-items: center;
}
header nav a svg {
	margin-right: 5px;
	font-size: 14px;
}
header nav a:hover {
	color: #FF0;
}


main {
	display: flex;
	flex: 1;	
}


main section {
	padding: 15px;	
	flex: 1;
	overflow-y: auto;
	height: calc(100vh - 50px - 30px);
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;	
}
main section nav {
	background: none;
}


footer {
	background: #222;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 11px;
}



main section h1 {		
	font-size: 22px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #333;
	padding: 0 15px;
	font-weight: 400;
}
main section h1 svg {
	margin-right: 7px;
}


.ant-modal-body h2, 
main section h2 {		
	font-size: 20px;
	display: flex;
	align-items: center;			
	border-bottom: 1px solid #333;
	margin-bottom: 15px;
	font-weight: 700;
}
.ant-modal-body h2 svg,
main section h2 svg {
	margin-right: 5px;
}




.area-parceiro .info {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 15px;
}


.area-parceiro .info .logo img {
	width: 100%;
	max-width: 250px;
}

.area-parceiro .info h1 {
	border: none;	
	margin: 0;
	font-size: 28px;	
}
.area-parceiro .info h1 svg {
	margin-left: 20px;
	margin-right: 0;
}
.area-parceiro .info h1 p {
	margin: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	line-height: normal;
}
.area-parceiro .info h1 strong {
	font-size: 20px !important;
	color: #0b82d3;
}



.box {
	background: #fff;
	padding: 15px !important;
	box-shadow: none;		
	border-radius: 10px;	
	height: auto;	
}
.box h2 {
	background: #555;
	color: #FFF;
	font-weight: 300;
	padding: 0 15px;
	font-size: 18px;
	display: flex;
	height: 40px;
	align-items: center;
	border: none;
	border-radius: 10px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.item-etapa {
	padding: 15px;
	margin-top: 15px;	
	margin-bottom: 0;
}

.item-etapa h3 {	
	display: flex;
	align-items: center;
	height: 40px;
	margin-bottom: 10px;
}
.item-etapa h3 svg {
	margin-right: 8px;
}

.item-etapa .upload {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
}
.item-etapa .upload h2 {
	background: none;
}



.parceiro-inicio .descricao {
	margin-bottom: 25px;
}

.parceiro-inicio .descricao p {
	background: #FFF;
	padding: 15px;	
	border-radius: 10px;
}
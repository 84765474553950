.tipo-cadastro {
	display: flex;	
}

.tipo-cadastro .item {
	height: 35px;
	padding: 0 15px;
	background: #F5F5F5;
	border: 1px solid #ddd;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
}
.tipo-cadastro .item.ativo {
	background: #555;
	color: #fff;
}


.tipo-cadastro .item:hover {
	cursor: pointer;
}
.tipo-cadastro .item svg {
	margin-right: 5px;
	font-size: 18px;
}